<template>
   <v-app>
    <v-system-bar v-if="false"></v-system-bar>
    <BookingAppBar :title="$t('booking.title')"/>
    <router-view/>
     
   </v-app>
</template>

<script>
import BookingAppBar from "@/components/booking/BookingAppBar";

export default {
    components: {
        BookingAppBar,
    },
}
</script>

<style>

</style>